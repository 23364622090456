<template>
  <main-layout>
    <main class="section" id="hero">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-7 has-text-centered-mobile hero-title">
            <h1 class="title is-2 is-size-4-mobile has-text-weight-black">
              <span class="has-text-secondary" v-t="`home.relax`"></span>
              <span v-t="`home.what-we-do`"></span>
            </h1>

            <p v-only-desktop class="has-text-grey is-size-4" v-t="`home.extension-description`"></p>

            <div>
              <Rating v-only-desktop></Rating>

              <i18n v-only-desktop path="home.rating.label" tag="span">
                <template #value>
                  <strong v-t="`home.rating.value`"></strong>
                </template>

                <template #max>
                  <strong>5</strong>
                </template>
              </i18n>
            </div>

            <p v-only-mobile class="has-text-grey is-size-2 is-size-6-mobile content-mobile" v-t="`home.pouch-on-desktop`"></p>

            <div class="has-text-left container content-mobile">
              <p-form v-only-mobile :handler="emailRegistration" :validator="emailValidator" class="columns is-gapless content-mobile">
                <template slot="fields" slot-scope="{ errors, data }">
                  <p-field :errors="errors.Email" class="column is-centered" style="margin: 2rem 0 1rem;">
                    <p-input type="email" :placeholder="$t('home.please-enter-email')" @keyup="toggleConsentInfo" v-model="data.Email"></p-input>
                  </p-field>

                  <div class="has-text-grey is-size-4 is-size-7-mobile" v-if="consentInfoActive" style="margin-top: 0.375rem 0 0.75rem;">
                    <span v-hidden-extension-fit v-t="`home.consent`"></span>
                    &nbsp;
                    <span> <a href="/privacy-policy" v-t="`links.privacy-policy`"></a>. </span>
                  </div>

                  <p-field class="column is-half" style="margin: 2rem 0 0.375rem">
                    <p-tickbox @change="data.ReceiveInfo = receiveInfoTextActive = $event"></p-tickbox>
                    &nbsp;
                    <span class="has-text-grey is-size-5 is-size-7-mobile" style="margin-bottom: 0.375rem" v-t="`home.instructions`"></span>
                  </p-field>

                  <p-field :errors="errors.Checkbox" class="column is-half">
                    <p-tickbox @change="data.ReceiveNewsletter = receiveNewsletterTextActive = $event"></p-tickbox>
                    &nbsp;
                    <span class="has-text-grey is-size-5 is-size-7-mobile" v-t="`home.newsletter`"></span>
                  </p-field>
                </template>

                <template slot="actions" slot-scope="{ stage, stages }">
                  <p-field class="column is-half">
                    <p-button
                      white
                      fullwidth
                      class="has-text-white has-background-primary"
                      style="margin: 2rem 0 3rem;"
                      :loading="stage === stages.SUBMITTED"
                      v-t="`home.join-email`"
                    ></p-button>
                  </p-field>
                </template>
              </p-form>
            </div>

            <div class="spacer is-medium"></div>

            <video style="object-fit: cover" controls :poster="$t('assets.videos.video-intro-preview-image')" class="has-cursor-pointer">
              <source :src="$t('assets.videos.video-intro')" type="video/mp4" />
            </video>
          </div>

          <div class="column is-4 is-offset-1" style="display: flex; flex-direction: column; justify-content: space-between;">
            <div class="box has-padding-1 has-text-centered pouch-banner" @click="openPropositionModal('never_overpay')">
              <div class="pouch-banner-image has-margin-2">
                <img v-lazy="`$/images/never_overpay_new.png`" :alt="$t('home.cards.never-overpay-label')" />
              </div>
              <div class="pouch-banner-content">
                <h3 class="title is-5 has-text-weight-black" v-t="`home.cards.never-overpay-label`"></h3>
                <span class="has-text-grey" v-t="`home.cards.never-overpay-des`"></span>
              </div>
            </div>

            <div class="box has-padding-1 has-text-centered pouch-banner" @click="openPropositionModal('exclusive_deals')">
              <div class="pouch-banner-image has-margin-2">
                <img v-lazy="`$/images/exclusive_deals_new.png`" :alt="$t('home.cards.exclusive-deals-label')" />
              </div>
              <div class="pouch-banner-content">
                <h3 class="title is-5 has-text-weight-black" v-t="`home.cards.exclusive-deals-label`"></h3>
                <span class="has-text-grey" v-t="`home.cards.exclusive-deals-des`"></span>
              </div>
            </div>

            <div class="box has-padding-1 has-text-centered pouch-banner" @click="openPropositionModal('its_free')">
              <div class="pouch-banner-image has-margin-2">
                <img v-lazy="`$/images/its_free_new.png`" :alt="$t('home.cards.its-free-label')" />
              </div>
              <div class="pouch-banner-content">
                <h3 class="title is-5 has-text-weight-black" v-t="`home.cards.its-free-label`"></h3>
                <span class="has-text-grey" v-t="`home.cards.its-free-des`"></span>
              </div>
            </div>

            <div class="box has-padding-1 has-text-centered pouch-banner" @click="openPropositionModal('data_protection')">
              <div class="pouch-banner-image has-margin-2">
                <img v-lazy="`$/images/data_protection_new.png`" :alt="$t('home.cards.data-protection-label')" />
              </div>
              <div class="pouch-banner-content">
                <h3 class="title is-5 has-text-weight-black" v-t="`home.cards.data-protection-label`"></h3>
                <span class="has-text-grey" v-t="`home.cards.data-protection-des`"></span>
              </div>
            </div>

            <add-to-browser medium bold position="Homepage">
              <span class="pouch-add-to-browser" v-t="`buttons.add-pouch`"></span>
            </add-to-browser>
          </div>
        </div>
      </div>
    </main>

    <section class="section is-medium" id="retailers">
      <h2 class="title is-3 has-text-weight-black has-text-centered">
        <span class="has-text-secondary" v-t="`home.save`"></span>
        &nbsp;
        <span v-t="`home.smart`"></span>
      </h2>

      <div class="container">
        <div class="columns is-mobile is-multiline is-gapless has-text-centered">
          <div class="column is-2-tablet is-6-mobile" v-for="(retailer, key) in featuredRetailers" :key="key">
            <img v-lazy="retailer.image" :alt="retailer.domain" style="max-width: 62%" />
          </div>
        </div>
      </div>
    </section>

    <section class="section" id="testimonials">
      <h2 class="title is-3 has-text-weight-black has-text-centered">
        <span class="has-text-secondary" v-t="`home.exclusive`"></span>
        &nbsp;
        <span v-t="`home.join-our-community`"></span>
      </h2>

      <testimonials></testimonials>
    </section>
    <section class="section is-medium" id="press">
      <h2 class="title is-3 has-text-weight-black has-text-centered">
        <span class="has-text-secondary" v-t="`home.loved`"></span>
        &nbsp;
        <span v-t="`home.bbc-ads`"></span>
      </h2>

      <div class="container">
        <div class="columns is-mobile is-multiline is-gapless has-text-centered">
          <div class="column is-3-tablet is-6-mobile" v-for="(publication, key) in featuredPublications" :key="key">
            <img v-lazy="publication.logo" :alt="publication.name" style="max-width: 68%" />
          </div>
        </div>
      </div>
    </section>

    <proposition-modal :active="propositionModalActive" :part="propositionModalPart" @close="propositionModalActive = false"></proposition-modal>
  </main-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import EmailValidator from '@/core/validators/EmailValidator'

import AddToBrowser from '@/views/partials/AddToBrowser'
import PropositionModal from '@/views/partials/PropositionModal'

import Rating from '@/views/components/Rating'
import Testimonials from '@/views/components/Testimonials'

export default {
  components: { AddToBrowser, Testimonials, PropositionModal, Rating },

  data: () => ({
    receiveInfoTextActive: false,
    receiveNewsletterTextActive: false,
    emailValidator: new EmailValidator(),
    propositionModalActive: false,
    propositionModalPart: null,
    consentInfoActive: false
  }),

  computed: {
    ...mapState({
      featuredRetailers: state => state.featuredRetailers
    }),

    ...mapGetters({
      featuredPublications: 'featuredPublications'
    }),

    redirect () {
      return crossDeviceSupport.mobileOs() ? '/desktop-only' : '/unsupported-browser'
    }
  },

  methods: {
    async emailRegistration (data) {
      if (!data.Email || !data.Email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
        return Promise.reject({ code: 'InvalidParameterException' })
      }

      if (!data.ReceiveInfo && !data.ReceiveNewsletter) {
        return Promise.reject({ code: 'InvalidParameterException' })
      }

      /**
       * Subscribes to Onboarding
       */
      if (data.ReceiveInfo) {
        const mailchimpTemplateOnboarding = this.$mailchimp.getTemplate(data.Email, 'mobile_onboarding')
        this.$store.dispatch('postDataToMailchimp', mailchimpTemplateOnboarding).catch(err => console.log(err))
        this.$messages.success(this.$t('home.congratulations-on-sign-up'), '', true)
      }

      /**
       * Subscribes to the Newsletter
       */
      if (data.ReceiveNewsletter) {
        const mailchimpTemplateNewsletter = this.$mailchimp.getTemplate(data.Email, 'mobile_newsletter')
        this.$store.dispatch('postDataToMailchimp', mailchimpTemplateNewsletter).catch(err => console.log(err))
        this.$messages.success(this.$t('home.congratulations-newsletter'), '', true)
      }

      this.$router.push(this.redirect)
    },

    openPropositionModal (part) {
      this.propositionModalActive = true
      this.propositionModalPart = part
    },

    toggleConsentInfo (event) {
      this.consentInfoActive = !!event.target.value
    }
  }
}
</script>
